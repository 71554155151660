.appContainer {
    display: flex;
    flex-direction: column;
    min-block-size: 100vh;
    min-block-size: 100dvh;
    margin-inline: auto;

    *::selection {
        background: #ffa;
    }
}

.pageBackground {
    background-color: var(--page-background, var(--composition-background-neutral, #fdf4eb));
}
