:root {
    --reach-tooltip: 1;
    --marketist-link-decoration: underline;
    --marketist-link-hover-decoration: none;
    --marketist-link-color: var(--display-onlight-color-blue);
}

html,
body {
    min-block-size: 100vh;
    min-block-size: 100dvh;
}

html {
    :target {
        scroll-margin-top: var(--space-96);
    }

    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }
}

_::-webkit-full-page-media,
_:future,
:root .display-none-safari-only {
    display: none;
}
